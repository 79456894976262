
import './App.css';

import Page from './Page'
import "./fonts/Gambetta-Regular.ttf"
import "./fonts/helvetica_condensed.ttf"
import {Helmet} from "react-helmet";



function App() {

  return (
    <div className="App">
      <header className="App-header" >
      </header>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Gabriel Hafner</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      <Page/>
    </div>
  );
}

export default App;
